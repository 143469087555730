import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../common/product';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // private baseUrl = 'http://localhost:8080/api/products?size=10';

  public _products: Product[] = [
    new Product(
      'Playeras',
       169,
       'Playeras de hombre y mujer blancas',
       '../../assets/images/t-shirt tu diseño.png'
       ),
       new Product(
        'Sudaderas',
         249,
         'sudaderas de hombre y mujer blancas',
         '../../assets/images/sudadera tu diseño.png'
         ),
    new Product(
       'Pantuflas',
         169,
        'Pantuflas Ch, M y G',
         '../../assets/images/pantuflas tu diseño.png'
    ),
    new Product(
      'Sandalias',
      149,
      'sandalias con base de plástico y superficie de neopreno',
      '../../assets/images/sandalias tu diseño.png'
  ),
    new Product(
         'Rompecabezas',
         129,
        'Rompecabezas de 120 pzs',
         '../../assets/images/puzzle tu diseño.png'
    ),
    new Product(
      'Tazas',
      99,
      'Tazas blancas de 11oz',
      '../../assets/images/2 tazas tu diseño.png'
    ),
    new Product(
        'Cojines',
        149,
        'Cojin completo/funda de 35cm',
        '../../assets/images/cojin diseño.png'
    ),
    new Product(
        'Fundas para celular',
        179,
        'Fundas para celulares con borde y fondo de plastico duro',
        '../../assets/images/phone case tu diseño.png'
    ),
    new Product(
      'Marco de vidrio con espejo',
      199,
      'Marco de vidrio con orilla de espejo, con soporte de plastico',
      '../../assets/images/marco vidrio tu diseño.png'
    ),
    new Product(
        'Piedra rectangular',
        249,
        'Piedra rectangular de 20 x 30 cm',
        '../../assets/images/piedra tu diseño.png'
    ),
    new Product(
      'Piedra rectangular pequeña',
      189,
      'Piedra rectangular de 15 x 20 cm',
      '../../assets/images/piedra pequeña tu diseño.png'
    )
  ]

  get products() {
    return [...this._products];
  }


  constructor(
    private httpClient: HttpClient
    ) { }

    // getProductList(): Observable<Product[]> {
    //   return this.httpClient.get<GetResponse>(this.baseUrl).pipe(
    //     map(response => response._embedded.products)
    //   )
    // }
  }

    // interface GetResponse {
    //   _embedded: {
    //     products: Product[];
    //   }
    // }

