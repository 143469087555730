
  <!--Section: Features v.2-->
<section id="proceso" class="container text-center">

  <!--Section heading-->
  <h1 class=" py-5">¿Cómo funciona?</h1>
  <!--Section description-->
  <p class="px-5 mb-5 pb-3 lead grey-text">Contactanos y haznos saber que quieres trabajar con nosotros, envíanos tus
    diseños, una vez aprobados estará todo listo para que podamos ser tu proveedor dropshipping.</p>

  <!--Grid row-->
  <div class="row text-left text-center">

    <!--Grid column-->
    <div class="col-md-4 mb-4">
      <div class="col-1 col-md-2 float-left">
        <mdb-icon fas icon="store" size="2x" class="blue-text"></mdb-icon>
      </div>
      <div class="col-10 col-md-9 col-lg-10 float-right" >
        <h4 class="font-weight-bold">Ofrece</h4>
        <p class="grey-text">
          Ofrece tus productos en tienda en linea, redes sociales o en tu localidad.</p>
      </div>
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-4 mb-4">
      <div class="col-1 col-md-2 float-left">
        <mdb-icon fas icon="shopping-cart" size="2x" class="pink-text"></mdb-icon>
      </div>
      <div class="col-10 col-md-9 col-lg-10 float-right">
        <h4 class="font-weight-bold">Ordena</h4>
        <p class="grey-text">Cuando alguien haya comprado un producto tuyo, lo ordenas con nosotros, una vez pagado,
          es producido y envíado directo a tu cliente bajo tu marca, de esta manera no tienes que preocuparte de
          inventarios ni logisticas de envíos, nosotros nos encargamos.</p>
      </div>
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-md-4 mb-4">
      <div class="col-1 col-md-2 float-left">
        <mdb-icon fas icon="dollar-sign" size="2x" class="purple-text"></mdb-icon>
      </div>
      <div class="col-10 col-md-9 col-lg-10 float-right">
        <h4 class="font-weight-bold">Gana</h4>
        <p class="grey-text">Tu ganancia será la diferencia del costo de producirte tus productos y del precio
          al que lo vendas, los costos de envío te los proporcionamos en el momento en que te contactes con nosotros,
          tu decides si absorver una parte del costo de envío o dejarlo 100% a cuenta de tus clientes.
        </p>
      </div>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

  <div>
    <p>Recuerda que tambien puedes ofrecer productos de nuestro catalogo y ordenar por mayoreo.</p>
  </div>

</section>
<!--Section: Features v.2-->
<div class="container">
  <hr>
</div>
<br><br><br>
