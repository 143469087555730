<section id="productos" class="container d-flex flex-column text-center">
  <div class="m-3 mt-5"><h1>Conoce nuestros productos</h1></div>
  <div class="d-flex flex-row flex-wrap  "  >

    <mdb-card  style="border: none; box-shadow: none;" class="d-flex col-6 col-sm-4  my-3" *ngFor="let product of products" >

      <!--Card image-->
      <div   class="flex-grow-2 overlay " >
        <mdb-card-img style="width: 100%; height: 100%;" [src]="product.imageUrl" alt=""></mdb-card-img>

      </div>
      <!--Card image-->

      <!--Card content-->
      <mdb-card-body class="text-center">
        <!--Category & Title-->
        <h6 class="dark-grey-text">
          <strong>{{product.name}}</strong>
        </h6>




        <span >
          Desde
          <strong class="blue-text">{{product.price | currency}}</strong>
        </span>

      </mdb-card-body>
      <!--Card content-->

    </mdb-card>
    <!--Card-->

  </div>
</section>
<div class="container">
  <hr>
</div>
