export class Product {

  constructor(
   public name: string,
   public price: number,
   public description: string,
   public imageUrl: string,
   public active?: boolean,
   public unitsInStock ?: number,
   public dateCreated ?: Date,
   public lastUpdated ?: Date,
   public sku?: string,

  ) {}





}
