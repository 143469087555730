import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ScrollToDirective } from './directives/scroll-to.directive';

import { AppComponent } from './app.component';
import { ProductListComponent } from './componentes/product-list/product-list.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductService } from './services/product.service';
import { HomeComponent } from './components/home/home.component';
import { IntroComponent } from './components/intro/intro.component';
import { ProductosComponent } from './components/productos/productos.component';
import { RouterModule, Routes } from '@angular/router';
import { ProcesoComponent } from './components/proceso/proceso.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { FooterComponent } from './components/footer/footer.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  }
]

@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    HomeComponent,
    IntroComponent,
    ProductosComponent,
    ScrollToDirective,
    ProcesoComponent,
    ContactoComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

  ],
  providers: [
    ProductService,
    MDBSpinningPreloader,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
