<section id="contacto" class="container text-center">
  <h1 class="py-5">Contáctanos</h1>

  <p class="px-5 mb-5 pb-3 lead grey-text">Contáctanos en nuestras redes sociales para mas información,
    vender con nosotros o comprar de nuestro catálogo.</p>

    <a mdbBtn  class="blue-gradient waves-light" mdbWavesEffect href="https://www.facebook.com/berthnm">
      <mdb-icon fab icon="facebook-f" class="left"></mdb-icon>
      <span>Facebook</span>
    </a>

    <a mdbBtn  class="purple-gradient waves-light" mdbWavesEffect href="https://www.instagram.com/berthnm_/">
      <mdb-icon fab icon="instagram" class="left"></mdb-icon>
      <span>Instagram</span>
    </a>

</section>
