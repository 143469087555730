import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/common/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'product-list',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  products: Product[]

  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    // this.listProducts();
  }

  // listProducts() {
  //   this.productService.getProductList().subscribe(
  //     data => {
  //       this.products = data;
  //     }
  //   )
  // }

}
