<table class="table">

  <thead class="thead-dark">
    <tr>
      <th></th>
      <th>Name</th>
      <th>Price</th>
      <th>Units in Stock</th>
    </tr>
  </thead>

  <tbody>

    <tr *ngFor="let tempProduct of products">
        <td class="align-middle">
          <img style="height: 50px; width: 50px;" src=" {{ tempProduct.imageUrl}} " alt="">
        </td>
        <td class="align-middle">{{ tempProduct.name }}:</td>
        <td class="align-middle">{{ tempProduct.unitPrice | currency:'USD' }}</td>
        <td class="align-middle"> {{tempProduct.unitsInStock}} </td>

    </tr>

  </tbody>
</table>




