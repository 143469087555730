<!-- Main navigation -->
<header id="intro">
  <!--Navbar-->
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
    <mdb-navbar-brand>
      <a class="logo navbar-brand waves-light" mdbWavesEffect href="#"><strong style="color: black;">Berth & M</strong></a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item  waves-light" mdbWavesEffect>
          <a class="nav-link" appScrollTo="intro" style="color: black;"  >Inicio <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" appScrollTo="productos" style="color: black;">Productos</a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" appScrollTo="proceso" style="color: black;">¿Cómo funciona?</a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" appScrollTo="contacto" style="color: black;">Contáctanos</a>
        </li>
      </ul>
      <form class="form-inline">
        <div class="md-form my-0">
          <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
        </div>
      </form>
    </links>
  </mdb-navbar>
  <!-- Full Page Intro -->
  <div class="view" style="background-image: url('https://img.freepik.com/foto-gratis/direccion-escritura-caja_1098-21122.jpg?size=626&ext=jpg&ga=GA1.2.2007245168.1612766151'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-6 white-text text-center text-md-left mt-xl-5 mb-5 wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="h1-responsive font-weight-bold mt-sm-5">Empieza tu negocio con poca inversión. </h1>
            <hr class="hr-light">
            <h6 class="mb-4">Diseña y ofrece tus productos, nosotros nos encargamos de realizar tus pedidos, empacarlos y
              enviarlos a tus clientes bajo tu marca.
            </h6>
            <a mdbBtn color="elegant" mdbWavesEffect appScrollTo="contacto">Contáctanos</a>
            <a mdbBtn color="white" outline="true" appScrollTo="productos" mdbWavesEffect>Ver productos</a>
          </div>
          <!--Grid column-->
          <!--Grid column-->
          <!-- <div class="col-md-6 col-xl-5 mt-xl-5 wow fadeInRight" data-wow-delay="0.3s">
            <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png" alt="" class="img-fluid">
          </div> -->
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>
  <!-- Full Page Intro -->
</header>
<!-- Main navigation -->
